import { GET, POST } from "../lib/axios";
import { fgetUser } from "../lib/Firebase";

async function getConfiguration(menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`uiDashboard/v1/${menu_id}/getConfiguration`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getMetadata(menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`uiDashboard/v1/${menu_id}/getMetadata`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getOrderDetails(epochStartDate, epochEndDate) {
  let body = {
    epochStartDate : epochStartDate,
    epochEndDate : epochEndDate
  }
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`uiDashboard/v1/getOrdersForSupport`, body , {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getPayoutDetails(epochStartDate, epochEndDate) {
  let body = {
    epochStartDate : epochStartDate,
    epochEndDate : epochEndDate
  }
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`uiDashboard/v1/getPayoutsForSupport`, body , {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function addUser(data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`support/v1/addUser`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function removeUser(email) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`support/v1/removeUser`, email, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function cancelDeliveryPartner(order_id, data = {
  "source": "support"
}) {
  return new Promise((resolve, reject) => {
    POST(`api/deliveryModule/v1/${order_id}/cancel`, data, {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
        .then(res => resolve(res.data))
        .catch(err => reject(err));
  });
}

async function assignDeliveryPartner(order_id, dp_id, data, type = "assign-delivery-partner") {
  if (type === "assign-delivery-partner") {
    await cancelDeliveryPartner(order_id);
  }
  return new Promise((resolve, reject) => {
    POST(`deliveryPartners/v1/${dp_id}/${order_id}/acceptOrderByDp`, data, {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function markDelivered(order_id, dp_id, data) {
  return new Promise((resolve, reject) => {
    POST(`deliveryPartners/v1/${dp_id}/${order_id}/markDelivered`, data, {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function getOrderData(order_id) {
  return new Promise((resolve, reject) => {
    GET(`menu/v1/order/${order_id}`, {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function cancelOrder(order_no) {
  return new Promise((resolve, reject) => {
    POST(`menu/v1/order/${order_no}/cancel`, {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function cancelOrderByLoc(order_no) {
  return new Promise((resolve, reject) => {
    POST(`menu/v1/order/${order_no}/cancelOrderByLoc`, {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}


async function markOrderCompleted(orderNo, data) {
  let restaurantId = orderNo.toString().split("-")[0];
  return new Promise((resolve, reject) => {
    POST(`uiDashboard/v1/${restaurantId}/orders/${orderNo}/markOrderCompleted`, data, {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function checkQRCode(uuID) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`support/v1/checkQRCode/${uuID}`, header)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function updateQRCodeURL(data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`support/v1/updateQRCode`, data, header)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function getWeFastBalance() {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`support/v1/getWeFastDetails`, header)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function getBaseURL(menuID) {
  return new Promise((resolve, reject) => {
    GET(`menu/v1/domain/${menuID}`, {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function createPayout(order_id) {
  return new Promise((resolve, reject) => {
    POST(`menu/v1/payout/${order_id}`, {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function changeLoggedInStatus(data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`support/v1/changeLoggedInStatus`, data, header)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function generateQRCode() {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`support/v1/generateQRCode`, header)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function checkValidity(ID) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`support/v1/checkValidity/${ID}`, header)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}

async function getAccessToken() {
  let token = await fgetUser();
  let header = null;
  if (token) {
    header = { Authorization: "Bearer " + token };
  } else {
    token = JSON.parse(localStorage.getItem("DASHBOARD_USER_AUTH"))
      ?.stsTokenManager.accessToken;
    header = { Authorization: "Bearer " + token };
  }
  return header;
}

async function fetchELKOrderData(order_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`menu/v1/elk/${order_id}`, {Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`})
        .then(res => resolve(res.data))
        .catch(err => reject(err))
  });
}
export {
  getConfiguration,
  getMetadata,
  getOrderDetails,
  getPayoutDetails,
  addUser,
  removeUser,
  cancelDeliveryPartner,
  assignDeliveryPartner,
  markDelivered,
  getOrderData,
  markOrderCompleted,
  updateQRCodeURL,
  getWeFastBalance,
  getBaseURL,
  cancelOrder,
  cancelOrderByLoc,
  changeLoggedInStatus,
  generateQRCode,
  checkQRCode,
  createPayout,
  checkValidity,
  fetchELKOrderData
};
